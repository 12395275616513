.HomeBanner{
    width: 100%;
    position: relative;
    height: max-content;
    padding-bottom: 40px;
}



.studioBannerImg{
    /* position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: ; */
    width: 100%;
}

.HomeBannerContainer{
    padding-top: 50px !important;
    display: flex;
    justify-content: space-between;
    padding: 0px 4rem;
    z-index: 0;
    /* position: absolute; */
    top: 0px;
    color: white;
    position: relative;
}


.studioBannerImg img{
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.yogaClassDescription{
    width: 55%;
}
.studioSession{
    display: flex;
}
.studioSession h3{
    font-family: "AvenirMedium";
    font-size: 32px;
    font-weight: 750;
    background: #FCE2E3;
    border-radius: 4px;
    color: #ffffff;
    padding: 8px;
    margin-bottom: 8px;
}
.yogaClassDescription h2{
    font-style: normal;
    font-weight: 750;
    font-size: 45px;
    line-height: 156.5%;
    margin-bottom: 0px;
    font-style: normal;
    font-feature-settings: "tnum" on,"lnum" on;
}

.yogaClassDescription h3{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    font-style: normal;
    margin-top: 12px;
}


.yogaClassDescription .para{
    font-size: 18px;
    opacity: 0.9;
    margin-top: 13px;
}

.yogaClassDescription .benefitsContainerParent{
    height: 100%;
}
.HomeBanner .studioImg{
    width: 40%;
    background: #FFFFFF;
    box-shadow: 12px 15px 59px rgba(0, 0, 0, 0.25);
    border-radius: 26px;
}
.BookTrailstudioForm{
    background: #FFFFFF;
    /* box-shadow: 12px 15px 59px rgba(0, 0, 0, 0.25); */
    border-radius: 26px;
}
.HomeBanner .studioImg img{
    height: 740px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.benefitsContainerParent{
    height: 100%;
}
.benefitsContainer{
    width: 100%;
    margin-top: 40px;
    display: flex;
    /* gap: 20px; */
    justify-content: space-between;
}
.benefitsContainer .benefits{
    display: flex;
    width: 48%;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.benefitsContainer .benefits .benefitsPara{
    margin-left: 12px;
    
}
.benefitsContainer .benefits .benefitsPara h4{
    font-weight: 750;
    font-size: 18px;
    
}

.benefitsContainer .benefits .benefitsPara span{
    display: none;
    font-weight: 300;
    font-size: 16px; 
    
}
.benefitSpan{
    display: none;
}
.benefitsContainer .benefits:hover .benefitsPara span{
    display: block;
    position: absolute; 
    top: 100%;
    left: 30px;
    z-index: 100;
    padding: 8px 20px;
    background-color: rgb(255, 255, 255);
    color: #000000;
    border-radius: 6px;
}   
.studioImgResp{
    display: none;
}
.BookTrailstudioForm {
    padding: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.BookTrailstudioForm h2{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 156.5%;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F0212D;
}

.BookTrailstudioForm h3{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    margin-top: 10px;
}

.studioTrailInputs{
    display: flex;
    flex-direction: column;
    color: black;
    width: 100%;
    margin-top: 22px;
}
.studioTrailInputs label{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156.5%;
    color: #000000;
    margin-bottom: 6px !important;
}
.studioTrailInputs input{
    width: 100%;
    padding: 10px 24px;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    outline: none;
    border-radius: 50px;
}

.BookTrailstudioForm button{
    width: 100%;
    padding: 10px 0px;
    background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
    border-radius: 50px;
    font-weight: 750;
    font-size: 24px;
    line-height: 156.5%;
    border: none;
    outline: none;
    color: white;
    margin-top: 32px;
}

.respstudioBannerImg{
    display: none;
}

.benefitsContainerParent h3{
    margin-top: 26px;
}   

@media screen and (max-width: 500px) {
    .respstudioBannerImg{
        display: flex;
        width: 100%;
    }

    .respstudioBannerImg img{
        display: flex;
        width: 100%;
    }

    .yogaClassDescription h3{
        font-size: 16px;
    }
}

@media screen and (max-width: 1110px) {
    .studioBannerImg{
        display: none;
    }
    .HomeBannerContainer{
        position: relative;
        color: black;
    }
}

@media screen and (max-width: 766px) {
    .HomeBanner{
        padding: 0px;
        margin-top: 28px !important;
    }
    .HomeBannerContainer{
        padding-top: 0px !important;
        display: flex;
        justify-content: space-between;
        padding: 0px 1rem;
        z-index: 0;
        position: relative;
        top: 0px;
        color: #212121;
        /* position: relative; */
    }
    /* .BookTrailstudioFormResp h2{
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 156.5%;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #F0212D;
    }
    .BookTrailstudioFormResp{
        padding: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        /* box-shadow: 12px 15px 59px rgba(0, 0, 0, 0.25); 
        border-radius: 26px;
    }
    .BookTrailstudioFormResp button{
        width: 100%;
        padding: 10px 0px;
        background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
        border-radius: 50px;
        font-weight: 750;
        font-size: 18px;
        line-height: 156.5%;
        border: none;
        outline: none;
        color: white;
        margin-top: 32px;
    }
    .studioTrailInputs label{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 156.5%;
        color: #000000;
    }
    .studioTrailInputs input{
        margin-top: 6px;
        width: 100%;
        padding: 8px 24px;
        background: #FFFFFF;
        border: 1px solid #C8C8C8;
        border-radius: 50px;
        font-size: 14px;
        outline: none;
    }
    
    .BookTrailstudioForm button{
        width: 100%;
        padding: 10px 0px;
        background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
        border-radius: 50px;
        font-weight: 750;
        font-size: 18px;
        line-height: 156.5%;
        border: none;
        outline: none;
        color: white;
        margin-top: 32px;
    } */
    .studioBannerImg{
        display: none;
    }
    .studioSession h3{
        font-size: 16px;
        font-weight: 750;
        background: #FCE2E3;
        border-radius: 4px;
        color: #212121;
        padding: 8px;
        margin-bottom: 8px;
    }
    .studioImg{
        display: flex;
        width: 100% !important;
    }
    .yogaClassDescription{
        width: 100%;
    }
    .yogaClassDescription h2{
        font-size: 20px;
        /* text-align: center; */
    }
    .yogaClassDescription .para{
        font-size: 14px !important;
        /* text-align: center; */
    }
    .benefitsContainer{
        flex-direction: column;
        margin-top: 0px;
        gap: 0px;
    }

    .benefitsContainer .benefits{
        width: 100%;
        margin-top: 20px;
    }
    .benefitsContainer .benefits img{
        width: 28px;
        height: 28px;
    }

    .benefitsContainer .benefits .benefitsPara h4{
        font-weight: 750;
        font-size: 16px;
        margin-bottom: 0px;
    }
    
    .benefitsContainer .benefits .benefitsPara span{
        display: block;
        font-weight: 300;
        font-size: 14px;
    }
    .studioImgResp{
        display: flex;
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .studioImgResp img{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 900px) {
    .HomeBanner{
        padding: 0px;
        margin-top: 48px;
    }
    .studioBannerImg{
        display: none;
    }
    .HomeBannerContainer{
        padding-top: 0px !important;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 1rem;
        z-index: 0;
        position: relative;
        top: 0px;
        color: #212121;
        /* position: relative; */
    }
    .HomeBanner .studioImg{
        display: flex;
        width: 100%;
        margin-top: 24px;
        box-shadow: none ;
    }

    .BookTrailstudioForm {
        padding: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .BookTrailstudioForm h2{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 156.5%;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #F0212D;
    }

    .yogaClassDescription h3{
        font-size: 18px;
    }

    .BookTrailstudioForm label{
        font-size: 14px;
    }

    .BookTrailstudioForm input{
        font-size: 14px;
    }


    .BookTrailstudioForm button{
        width: 100%;
        padding: 10px 0px;
        background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
        border-radius: 50px;
        font-weight: 750;
        font-size: 18px;
        line-height: 156.5%;
        border: none;
        outline: none;
        color: white;
        margin-top: 32px;
    } 



    .studioSession p{
        font-size: 16px;
        font-weight: 750;
        background: #FCE2E3;
        border-radius: 4px;
        color: #212121;
        padding: 8px;
        margin-bottom: 8px;
    }
    .yogaClassDescription{
        width: 100%;
    }
    .yogaClassDescription h2{
        font-size: 20px;
        /* text-align: center; */
    }
    .yogaClassDescription .para{
        font-size: 14px;
        /* text-align: center; */
    }
    .benefitsContainer{
        display: flex;
        
    }

    .benefitsContainer .benefits{
        width: 100%;
    }
    .benefitsContainer .benefits img{
        width: 32px;
        height: 32px;
    }

    .benefitsContainer .benefits .benefitsPara h4{
        font-weight: 750;
        font-size: 16px;
        margin-bottom: 0px;
    }
    
    .benefitsContainer .benefits .benefitsPara span{
        font-weight: 300;
        font-size: 14px;
        display: block;
    }
    .studioImgResp{
        display: flex;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .studioImgResp img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
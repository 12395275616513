.burgerMenu {
	width: 100vw;
	height: 100vh;
	background-color: #fff3e9;
	z-index: 5;
}
.burgerMenu .menuItem {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 20px;
	line-height: 156.5%;

	/* or 31px */
	font-feature-settings: "tnum" on, "lnum" on;

	color: rgba(141, 141, 141, 0.795);
	margin-top: 2rem;
}
.burgerMenu .menuItem.home {
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.burgerMenu .contact {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px 32px;
	gap: 8px;

	margin-top: 2rem;

	border: 1px solid #626262;
	border-radius: 40px;
}
.burgerMenu .contact .text {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;

	/* Gradient Primary */
	background: linear-gradient(
		90deg,
		#fe5266 1.39%,
		#f84b7c 48.64%,
		#f24397 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.burgerMenunav {
	background: #fff3e9;
	/* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05); */
	height: max-content;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
}
.burgerMenunav .joinToday {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;

	color: #ffffff;

	/* test gradient */
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	border-radius: 50px;
}
.stopScroll {
	overflow: hidden;
}
@media only screen and (max-width: 500px) {
	/*Tablets [601px -> 1200px]*/
	.burgerMenunav .joinToday {
		font-size: 14px;
		line-height: 156.5%;
		cursor: pointer;
	}
}

.review .swiper {
	width: 100%;
	height: 100%;
}

.review .swiper-slide {
	text-align: center;
	font-size: 18px;
	/* background: #fff; */

	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
.swiper-slide > div {
	aspect-ratio: 16/9;
}
.review .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.review .swiper-pagination-bullet {
	width: 8px;
	height: 8px;
	text-align: center;
	line-height: 20px;
	font-size: 12px;
	color: #000;
	opacity: 1;
	/* background: rgba(0, 0, 0, 0.2); */
	background: rgb(255, 255, 255);

	border: 2px solid #f21623;
}

.review .swiper-pagination-bullet-active {
	/* color: #fff;
	background: #007aff; */
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
}

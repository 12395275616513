.SpecialOffers{
    margin-top: 60px;
}
.SpecialOffers .specialOfferHeader{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.SpecialOffers .specialOfferHeader h2{
    font-weight: 750;
    font-size: 48px;
    color: #EA4B54;
    margin-bottom: 0px;
    font-style: normal;
    font-feature-settings: "tnum" on,"lnum" on;
}

.SpecialOffers .specialOfferHeader p{
    font-weight: 400;
    font-size: 18px;
    color: #212121;
    opacity: 0.7;
    margin: 24px 0px 0px 0px;
}

.offersContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 30px; */
    width: 100%;
    margin-top: 24px;
}

.offersContainer .offers{
    width: 33.33%;
    height: 164px;
    background: #FDF8F4;
    border-radius: 20px;
    border: 1px solid #FE5266;
    font-weight: 500;
    font-size: 32px;
    display: flex;
    margin:0px 1rem;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
    cursor: pointer;
}

@media only screen and (max-width: 820px) {
    .offersContainer{
        flex-direction: column;
        padding: 0px 1rem;
     }
 
     .offersContainer .offers{
         width: 100%;
         font-size: 24px;
     }

     .offersContainer .offers2{
        margin: 1rem 0px;
     }
}

@media only screen and (max-width: 766px) {

    .SpecialOffers .specialOfferHeader h2{
        font-weight: 700;
        font-size: 32px;
        color: #EA4B54;
        margin-bottom: 0px;
    }
    .SpecialOffers .specialOfferHeader p{
        font-size: 16px;
        text-align: center;
    }

    .offersContainer{
       flex-direction: column;
    }

    .offersContainer .offers{
        width: 100%;
        font-size: 24px;
    }
    
}
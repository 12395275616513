body {
	font-family: "Avenir LT Std", sans-serif !important;
}


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Avenir LT Std", sans-serif;
}
.modal-content {
	background: #fdf8f4;
	border-radius: 10px;
}
body {
	background: #fdf8f4;
}
/* .ReactPlayer {
	height: max-content !important;
} */

.nav {
	background: #fdf8f4;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	height: max-content;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	position: sticky;
	top: 0;
	z-index: 2;
}

.navBrand {
	width: max-content;
}

.nav .contact {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 32px;
	gap: 8px;
	border: 1px solid #ea4b539d;
	border-radius: 40px;
}

.ChatWithUs {
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;
	font-feature-settings: 'tnum' on, 'lnum' on;
	background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.contact:hover {
	border: 1px solid #ea4b54;
	border-width: 1px;
	border-style: solid;

}

.nav .contact .text {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;

	/* Gradient Primary */
	background: linear-gradient(90deg,
			#fe5266 1.39%,
			#f84b7c 48.64%,
			#f24397 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.nav .joinToday {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 24px;
	gap: 8px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;

	color: #ffffff;

	/* test gradient */
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	border-radius: 50px;
}

.joinToday {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px;
	gap: 8px;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 18px;
	line-height: 156.5%;
	width: fit-content;
	/* or 28px */
	font-feature-settings: "tnum" on, "lnum" on;

	color: #ffffff;

	/* test gradient */
	background: linear-gradient(90.56deg, #f21623 -27.33%, #ea4b54 137.21%);
	border-radius: 50px;
}

.stopScroll {
	overflow: hidden;
}

.webLogo {
	display: inline-block;
}

.mblLogo {
	display: none;
}

@media only screen and (max-width: 500px) {

	/*Tablets [601px -> 1200px]*/
	.nav .joinToday {
		font-size: 14px;
		line-height: 156.5%;
	}

	.navBrand {
		width: 49vw
	}

	.webLogo {
		display: none;
	}

	.mblLogo {
		display: inline-block;
		width: inherit;
	}

	.nav .joinToday {
		padding: 10px 20px;
	}
}
.burcityLocationImg{
    margin: 40px 28px 0px 28px;
    display: flex;
    /* flex-wrap: wrap; */
}

.burcityLocationImg .locationImg img{
    width: 290px;
    height: 300px;
}

.locationMap{
    width: 100%;
    height: auto;
    margin-left: 27px;
}
.locationNameResponsive{
    display: none;
}
.locationMap .locationName{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.locationName .currentLocation {
    display: flex;
    align-items: center;
}
.locationName .currentLocation img{
    width: auto;
    height: auto;
    margin-right: 10px;
}
.locationName .currentLocation span{
    font-weight: 300;
    font-size: 18px;
}

.locationMap .locationName p{
    font-weight: 400;
    font-size: 28.83px;
    margin: 0px;
    height: auto;
}

.burcityLocationImg .mapLocation{
    width: 100%;
    height: 250px;
}

.BurCityLocation .divider{
    width: 95%;
    height: 1px;
    background-color: rgb(206, 199, 199);
    margin-top: 15px;
}
.TabsContainer{
    display: flex;
    height: 431px;
    /* flex-wrap: wrap; */
}

.ClassTabsContainer .classTabs{
    width: 221px;
    height: 50%;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    border-bottom: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
    cursor: pointer;
}

.ClassTabsContainer .activeClassTab{
    font-weight: 700;
    font-size: 28px;
    color: white;
    background: linear-gradient(90deg, #FE5266 1.39%, #F84B7C 48.64%, #F24397 100%);
}


.TabsContainer .ClassTabContent{
    margin: 16px 24px;
}

.TabsContainer .ClassTabContent h3{
    font-weight: 750;
    font-size: 16px;
    margin: 0px;
}


.TabsContainer .ClassTabContent .duration{
    font-weight: 300;
    font-size: 16px;
}
.normalTime{
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
}
.normalTime span{
    background: #DCDCDC;
    border-radius: 20px;
    padding: .5rem 1.1rem;
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.ladiesTime{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.ladiesTime span{
    background: #FEA3AC;
    border-radius: 20px;
    padding: .5rem 1.1rem;
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
}  

.differentiate{
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.differentiate .ladiesOnly{
    width: 11.38px;
    height: 13px;
    background: #FEA3AC;
}

.ClassTabContent h4{
    font-size: 16px;
    font-weight: 700;
}
.ClassTabContent .moreSpan{
    font-size: 16px;
    font-weight: 400;
}
.BurCityLocation .contactDetails{
    margin-right: 25px;
}
.BurCityLocation .contactDetails .callDetails{
    display: flex;
    align-items: center;
}
.BurCityLocation .contactDetails .callDetails a{
    display: flex;
    align-items: center;
}
.BurCityLocation .contactDetails .callDetails p{
    margin-bottom: 0px;
    margin-left: 10px;
    color: black;
}



@media screen and (max-width: 776px) {
    /* .locationNameResponsive{
        display: flex;
    } */

    .locationNameResponsive{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .locationNameResponsive .currentLocation {
        display: flex;
        align-items: flex-start;
    }
    .locationNameResponsive .currentLocation img{
        width: auto;
        height: auto;
        margin-right: 10px;
    }
    .locationNameResponsive .currentLocation span{
        font-weight: 300;
        font-size: 14px;
    }
    
     .locationNameResponsive p{
        font-weight: 400;
        font-size: 16px;
        margin: 0px;
        height: auto;
    }



   .burcityLocationImg{
        margin: 0px 0px;
        display: flex;
        flex-direction: column;
        margin: 16px;
   }
   .burcityLocationImg .locationImg{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
   }
   .burcityLocationImg .locationImg img{
        /* width: 100% !important;
        height: auto; */
        max-width: 290px !important;
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
    }

    .burcityLocationImg  .mapLocation{
        width: 100%;
        height: 250px;
        margin: 1.4rem 0px 0px 0px;
    }
    .locationMap{
        width: 100%;
        height: auto;
        margin-left: 0px;
    }
    .ClassTabsContainer{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 16px;
    }
    .TabsContainer{
        display: flex;
        height: auto;
        flex-direction: column;
    }
    .TabsContainer .ClassTabContent{
        margin: 16px 16px;
    }
    .BurCityLocation .divider{
       display: none;
    }
    .ClassTabsContainer .classTabs{
        width: 50%;
        height: 40px;
        font-size: 12px;
        border: 1px solid #ECECEC;
    }
    .locationName .currentLocation span{
        font-weight: 300;
        font-size: 14px;
    }
    .locationMap .locationName {
        margin: 10px 0px;
        display: none;
    }
    .locationMap .locationName p{
        font-weight: 400;
        font-size: 16px;
        margin: 0px;
        height: auto;
    }

    .BurCityLocation .contactDetails{
        margin-right: 16px;
    }

    .contactDetails .BookNow{
        color: #FE5266;
    }
    .BurCityLocation .contactDetails .callDetails p{
        font-size: 12px;
    }
}


.StudioPrograms{
    margin-top: 60px;

}

.studioProgramsHeader{
    display: flex;
    justify-content: center;
    text-align: center;
}
.studioProgramsHeader h2{
    font-weight: 750;
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 156.33%;
    font-style: normal;
    font-feature-settings: "tnum" on,"lnum" on;
}

.programContainer{
    padding:0px .8rem;
    height: max-content !important;
    position: relative;
}


.programContainer .programs{
    width: auto !important;
    height: 100% !important;
    min-height: 490px !important;
    border-radius: 5px;
    border: 1px solid #F21623;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0px 1rem;
    padding-bottom: 20px;
}

.programContainer  .programs img{
    width: 100%;
    height: 290px;
    object-fit: cover;
}
.programContainer .programs h4{
    font-weight: 750;
    font-size: 24px;
    margin-top: 16px;
    text-align: center;
}
.programContainer .programs p{
    margin: 0px 34px;
}
.programContainer .programs p span{
    color: #F21623;
}

.chatWithExpert{
    margin-top: 24px;
    width: 100%;
    padding: 40px 60px;
    background: #FFF3E9;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chatWithExpert h4{
    font-weight: 750;
    font-size: 24px;
    width: 40%;
    line-height: 156.5%;
}
.chatWithExpert .chatBtn{
    border: 1px solid #F21623;
    border-radius: 50px;
    cursor: pointer;
}
.chatWithExpert .chatBtn p{
    margin: 11px 24px;
    font-weight: 750;
    font-size: 24px;
    color: #F21623;
}

@media screen and (max-width: 768px) {

    .StudioPrograms{
        margin-top: 48px;
    
    }
    .slick-dots li button{
        margin-top: 10px !important;
        border: 2px solid #F21623 !important;
        border-radius: 50% !important;
        padding: 0px !important;
        width: 8px !important;
        height: 8px !important;
        border: 1px solid red;
        color: white !important;
    }
    .slick-dots li button:before{
        margin-top: 10px !important;
        border: 4px solid #f21623 !important;
        border-radius: 50% !important;
        padding: 0px !important;
        color: white !important;
        width: 8px !important;
        height: 8px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slick-dots li.slick-active button:before{
        color: #F21623 !important;
       font-size: 8px;
      }

    .studioProgramsHeader h2{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
    }
    .programContainer .programs{
        width: 100% !important;
        margin-right: 20px;
        margin: 0px;
        /* height: auto !important; */
        min-height: 460px !important;
        padding-bottom: 20px;
    }
    .programContainer  .programs img{
        width: 100%;
        height: auto;
    }

    .chatWithExpert{
        margin-top: 40px;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
    }
    .chatWithExpert h4{
        font-weight: 750;
        font-size: 16px;
        width: 100%;
        line-height: 156.5%;
    }
    .chatWithExpert .chatBtn{
        border: 1px solid #F21623;
        border-radius: 50px;
        cursor: pointer;
    }
    .chatWithExpert .chatBtn p{
        margin: 11px 24px;
        font-weight: 750;
        font-size: 16px;
        color: #F21623;
    }
}   

.OurStudios{
   height: auto;
   margin-top: 60px;
   padding: 0px 1rem;
   font-family: "Avenir LT Std" !important;
}
.ourStudiosContainer{
    display: flex;
    justify-content: center;
}
.ourStudiosContainer h2{
    font-weight: 750;
    font-size: 32px;
    line-height: 156.5%;
	font-style: normal;
    font-feature-settings: "tnum" on,"lnum" on;
	color: #212121;
}

.ourStudiosContainer .outStudiosResp{
    display: none;
}
.ourStudiosParent{
    width: 100%;
    overflow-x: scroll;
}
.ourStudiosParent::-webkit-scrollbar{
    display: none;
}
.ourStudiosParent{
    -ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
    margin-top: 40px;
}
.ourStudiosTabContainer{
    display: flex;
    margin-top: 1.4rem;
    /* width: 100%; */
    /* flex-wrap: wrap; */
}

.ourStudiosTabContainer .ourStudiosTab{
   margin-right: 30px;
   font-weight: 300;
    font-size: 1.4rem;
    cursor: pointer;
    color: #000;
}

.ourStudiosTabContainer .activeOurStudiosTab{
    color: #FE5266;
    font-weight: 750;
    border-bottom: 5px solid #FE5266;
}


.MuiButtonBase-root{
    font-size: 25px!important;
    letter-spacing: 0px !important;
    text-transform: none !important;
}

.ourStudiosTabContent{
    width: 100%;
    height: auto;
    border:2px solid #FE5266;
    margin-top: 40px;
    border-radius: 5px;
}

.MuiTabs-scroller{
    overflow: scroll !important;
}
.MuiTabs-scroller::-webkit-scrollbar{
    display: none !important;
}

.MuiTabs-scroller{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 776px) {
    .OurStudios{
        margin-top: 48px;
    }
    .ourStudiosContainer h2{
        font-weight: 700;
        font-size: 24px;
    }

    .ourStudiosContainer .ourStudiosWeb{
        display: none;
    }
    .ourStudiosContainer .outStudiosResp{
        display: flex;
    }
    .ourStudiosTabContent{
        border: 1px solid #FE5266;
        margin-top: 0px;
        margin-top: 24px;
    }
    .ourStudiosTabContainer{
        display: flex;
        width: max-content;
        overflow-x: scroll;
        margin-top: 48px;
        margin-bottom: 1.4rem;
        padding: 0px !important;
    }
    .MuiButtonBase-root{
        font-size: 16px!important;
        letter-spacing: 0px !important;
        text-transform: none !important;
    }
    .ourStudiosTabContainer .ourStudiosTab{
        margin-right: 30px;
        font-weight: 300;
         font-size: unset;
         cursor: pointer;
       
     }
     .ourStudiosTabContainer .activeOurStudiosTab{
        color: #FE5266;
        font-weight: 750;
        border-bottom: 5px solid #FE5266;
    }
    .ourStudiosParent{
        width: 100%;
        overflow-x: scroll;
        margin-top: 14px;
    }
    .ourStudiosParent::-webkit-scrollbar{
        display: none !important;
    }
   
}
@font-face {
	font-family: 'Avenir LT Std';
	src:url('../public/fonts_Avenir/AvenirRegular.ttf')format("truetype")
}
@font-face {
	font-family: 'AvenirMedium';
	src:url('../public/fonts_Avenir/AVENIR-MEDIUM.TTF')format("truetype")
}
@font-face {
	font-family: 'AvenirHeavy';
	src:url('../public/fonts_Avenir/Avenir\ LT\ Std\ 85\ Heavy.otf')format("opentype")
}


h1{
	font-family: "Avenir LT Std", sans-serif !important;

}
body {
	font-family: "Avenir LT Std", sans-serif;
	background: #FDF8F4 !important;
}
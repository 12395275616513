.advertisementWrapper {
	margin-top: 60px;
}
.Advertisement {
	border: 1px solid rgba(242, 22, 35, 1);
	border-radius: 5px;
}
.OfferNeverBefore{
	font-family: 'Avenir LT Std';
font-style: normal;
font-weight: 750;
font-size: 32px;
line-height: 156.5%;
/* identical to box height, or 50px */

font-feature-settings: 'tnum' on, 'lnum' on;

/* test gradient */

background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.Advertisement .title {
    font-family: "Avenir LT Std";
    font-style: normal;
    font-weight: 300;
    font-size: 46px;
    line-height: 120.5%;
    color: #626262;
}
.timeCountdown {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 22.4602px;
	line-height: 156.5%;

	/* or 35px */
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;
	text-shadow: 0px 0px #00000000;
}
.pink {
	font-family: "Avenir LT Std" !important;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	color: #f21623;
}
.Advertisement .brief {
	font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;
	/* or 28px */
	
	font-feature-settings: 'tnum' on, 'lnum' on;
	
	/* Black */
	
	color: #212121;
	
	opacity: 0.7;
}
.TimeDatatype {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 16.8451px;
	line-height: 156.5%;
	/* or 26px */

	/* Black */

	color: #212121;
}
.remain {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 17px;
	line-height: 21px;
	/* identical to box height */

	text-align: center;

	/* Black */

	color: #212121;
}

.specialOfferBanner{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.specialOfferImg{
	position: relative;
}

.specialOfferImg p{
	position: absolute;
	bottom: 3%;
	left: 50%;
	transform: translateX(-50%);
	font-family: Avenir LT Std;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
}

.joinAddBtn{
	display: flex;
}
.specialOfferBanner img{
	mix-blend-mode: multiply;
}

@media only screen and (max-width: 991px) {
	
	.advertisementWrapper {
		margin-top:48px;
	}
	.Advertisement .title {
		font-weight: 300;
		font-size: 24px;
		text-align: center;
	}
	.Advertisement .brief {
		font-size: 14px;
	}
	.pink {
		font-weight: 300;
		font-size: 30px;
	}
	.timeCountdown {
		font-size: 25px;
	}
	.TimeDatatype {
		font-size: 14px;
	}
	.OfferNeverBefore{
		 font-size: 20px;
		line-height: 156.5%;
	}
	.OfferNeverBefore div{
		margin: 8px 0px;

	}
	.specialOfferBanner{
		display: flex;
		justify-content: center !important;
		align-items: center !important;
	}

	.specialOfferImg p{
		position: absolute;
		bottom: 3%;
		left: 50%;
		transform: translateX(-50%);
		font-family: Avenir LT Std;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}
	

}

@media screen and (max-width: 820px){
	.specialOfferImg p{
		position: absolute;
		bottom: 3%;
		left: 50%;
		transform: translateX(-50%);
		font-family: Avenir LT Std;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}
}

@media screen and (max-width: 768px) {
	.advertisementWrapper {
		margin-top:48px;
	}
	
	.Advertisement .title {
		font-weight: 300;
		font-size: 24px;
		text-align: center;
	}
	.Advertisement .brief {
		font-size: 14px;
	}
	.pink {
		font-weight: 300;
		font-size: 24px;
	}
	.timeCountdown {
		font-size: 20px;
	}
	.TimeDatatype {
		font-size: 14px;
	}
	.OfferNeverBefore{
		 font-size: 20px;
		line-height: 156.5%;
	}
	.OfferNeverBefore div{
		margin: 8px 0px;

	}
	.remain{
		font-family: 'Avenir LT Std';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;

	/* Black */

	color: #212121;

	}
	.specialOfferBanner{
		display: flex;
		justify-content: center !important;
		align-items: center !important;
	}

	.specialOfferImg p{
		position: absolute;
		bottom: 3%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: Avenir LT Std;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		margin-bottom: 0px;
	}

}


@media screen and (max-width: 600px) {
	.advertisementWrapper {
		margin-top:48px;
	}
	.joinAddBtn{
		display: none;
	}
	.Advertisement .title {
		font-weight: 300;
		font-size: 24px;
		text-align: center;
	}
	.Advertisement .brief {
		font-size: 14px;
	}
	.pink {
		font-weight: 300;
		font-size: 24px;
	}
	.timeCountdown {
		font-size: 20px;
	}
	.TimeDatatype {
		font-size: 14px;
	}
	.OfferNeverBefore{
		 font-size: 20px;
		line-height: 156.5%;
	}
	.OfferNeverBefore div{
		margin: 8px 0px;

	}
	.specialOfferBanner{
		display: flex;
		justify-content: center !important;
		align-items: center !important;
	}

	.specialOfferBanner img{
		mix-blend-mode: multiply;
	}
	.remain{
		font-family: 'Avenir LT Std';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		/* identical to box height */

		text-align: center;

		/* Black */

		color: #212121;
	}

	
}

@media screen and (max-width: 660px){
	.specialOfferImg p{
		position: absolute;
		bottom: 3%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: Avenir LT Std;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		margin-bottom: 0px;
	}
}

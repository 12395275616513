.EbookModal input {
    border-radius: 8px;
    height: 48px !important;
    background: #ffffff;
    border: none;
    border: 1px solid #C8C8C8;
    border-radius: 50px;
    padding: 1rem;
    outline: none;
}

.downloadEbookBtn{
    width: auto !important;
    padding: 0px 24px !important;
}


.GetEbook{
    width: 100%;
    background: #FDECE0;;
    display: flex;
    justify-content: center;
    margin-top: 90px;
    padding: 47px 0px 35px 0px;
}

.getEbookContainer{
    width: 1160px;
    display: flex;
    align-items: center;
    gap: 63px;
}

.ebookBanner{
    min-width: 495px;
}

.ebookBanner img{
    width: 100%;
}

.getEbookText{
    min-width: auto;
}

.getEbookText h1{
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
    color: #393A3B;
}

.getEbookText h4{
    font-family: 'Avenir LT Std' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #393A3B;
    margin: 20px 0px !important;
}

.getEbookText button{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 14px 24px;
    background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
    border-radius: 50px;
    border: none;
    outline: none;
}


@media screen and (max-width: 600px){

    .GetEbook{
        margin-top: 54px;
        padding: 25px 0px 25px 0px;
    }


    
    .getEbookContainer{
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
    }

    .ebookBanner{
        min-width: 100%;
        padding: 0px 21px;
    }
    
    .ebookBanner img{
        width: 100%;
    }
    
    .getEbookText{
        min-width: auto;
        padding: 0px 21px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }


    .getEbookText h1{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #393A3B;
        text-align: center;
    }
    
    .getEbookText h4{
        font-family: 'Avenir LT Std' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #393A3B;
        margin: 20px 0px !important;
        text-align: center;
    }
    
    .getEbookText button{
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        width: 100%;
    }
    



}